import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Background from "../components/Global/Background";
import Image from "../components/Global/Image";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Sections from "../components/Sections/Sections";
import SmartText from "../utils/TextHandler";

import WhiteCurveImage from "../images/background-curve-white.svg";
import FadeIn from "../hooks/fadeIn";

// ====

const Page = (props) => {
  const { data, pageContext, blok } = props;

  const fadeInRef = FadeIn();

  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
    image: doc.image,
  };

  const parsedSiteSettings = data?.settings
    ? JSON.parse(data?.settings.content)
    : {};

  return (
    <Layout location={props.location} siteSettings={data?.settings}>
      <Meta openGraph={openGraph} meta={doc.meta} />

      {doc?.header_image?.filename && (
        <section
          className={`
          container-fluid
          mx-3
          p-lg-0
          brand-border-radius-10
          brand-page-header
          ${doc?.has_curved_header ? "" : "brand-hero-top-margin"}
        `}
          style={{
            backgroundImage: `url(${doc?.header_background_image?.filename})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "calc(100% - 2rem)",
          }}
        >
          <div className="row justify-content-center align-items-center brand-text-white">
            {doc?.header_image && (
              <div
                className="
              col-12 col-lg-6
              px-0
              order-1 order-lg-0              
              position-relative brand-header-image-container
            "
              >
                <Image
                  ref={fadeInRef}
                  className="brand-header-image will-fade-in"
                  src={doc.header_image?.filename}
                  style={{ objectFit: "contain" }}
                  sm="600x0"
                  md="900x0"
                  lg="1200x0"
                  alt={doc.header_image?.alt}
                />
              </div>
            )}

            <div
              className="
              col-12 col-lg-6
              order-0 order-lg-1
              d-flex flex-column align-items-center justify-content-center
            "
            >
              {doc?.icon && (
                <Image
                  className="my-3"
                  src={doc.icon?.filename}
                  sm="250x75"
                  md="250x75"
                  lg="250x75"
                  alt={doc.icon?.alt}
                />
              )}

              {doc?.title && (
                <SmartText
                  containerClassName="
                    text-center
                    my-3
                    px-5
                    brand-title                    
                    title-slide-down-fade-in
                  "
                >
                  {doc.title}
                </SmartText>
              )}

              {doc?.body && (
                <div className="my-3 text-center px-5">
                  <SmartText>{doc.body}</SmartText>
                </div>
              )}

              {(doc?.apple_image?.filename || doc?.google_image?.filename) && (
                <div className="my-5 d-flex justify-content-center">
                  {doc?.apple_image?.filename && (
                    <Image
                      className="me-3 brand-border-radius-8"
                      src={doc?.apple_image?.filename}
                      sm="150x50"
                      md="200x50"
                      lg="200x50"
                      alt={doc?.apple_image?.alt}
                    />
                  )}
                  {doc?.google_image?.filename && (
                    <Image
                      className="brand-border-radius-8"
                      src={doc?.google_image?.filename}
                      sm="150x50"
                      md="200x50"
                      lg="200x50"
                      alt={doc?.google_image?.alt}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      )}

      {!doc?.header_image?.filename && (
        <section
          className={`brand-hero ${
            doc?.has_curved_header ? "" : "brand-hero-top-margin"
          } container-fluid p-0`}
        >
          <Background
            className={`
            d-flex align-items-center justify-content-center
          `}
            src={doc?.header_background_image?.filename}
            sm="400x1024"
            md="1920x800"
            lg="1920x585"
          >
            <div className="row justify-content-center align-items-center brand-text-white">
              <div>
                <SmartText containerClassName="text-center px-5 brand-title title-slide-down-fade-in">
                  {doc.title}
                </SmartText>

                {doc?.body && (
                  <div className="w-75 mx-auto text-center px-5">
                    <SmartText>{doc.body}</SmartText>
                  </div>
                )}
              </div>
            </div>
          </Background>

          {doc?.has_curved_header && (
            <img
              src={WhiteCurveImage}
              className="brand-white-curve px-0"
              alt="White curve"
            />
          )}
        </section>
      )}

      {doc?.sections && (
        <Sections items={doc.sections} settings={parsedSiteSettings} />
      )}
    </Layout>
  );
};

export default Page;

Page.propTypes = {
  pageContext: PropTypes.object,
  blok: PropTypes.object,
};

Page.defaultProps = {
  pageContext: null,
  blok: null,
};

export const QUERY = graphql`
  query PageQuery {
    settings: storyblokEntry(
      slug: { eq: "settings" }
      lang: { eq: "default" }
    ) {
      content
    }
  }
`;
