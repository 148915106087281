import { useRef } from "react";

export const FadeIn = () => {

    const elRef = useRef();

    let throttleTimer = false;

    const throttle = (callback, time) => {

        if (throttleTimer) return;

        throttleTimer = true;

        setTimeout(() => {
            callback();
            throttleTimer = false;
        }, time);
    };

    // Perhaps should change this to react to image sizes
    const scrollOffset = 150;

    const elementInView = (ref, offset = 0) => {
        const elementTop = ref.current?.getBoundingClientRect().top;

        return (
            elementTop <=
            (((typeof window !== "undefined" && window.innerHeight) || (!!document && document.documentElement.clientHeight)) - offset)
        );
    };

    const hasClass = (el, className) => {
        // Support for IE or we could use classList.contains()
        return (` ${el.className} `).indexOf(` ${className} `) > -1;
    };

    const animateElement = (ref) => {

        // Dont add class if it has already been assigned one!
        if (hasClass(ref.current, 'fade-in')) {
            return;
        }

        // If we pass in a direction use that, or generate a new direction
        ref.current?.classList.add(`fade-in`);

    };

    const handleScrollAnimation = () => {

        if (!(!!elRef?.current)) {
            return;
        }

        if (elementInView(elRef, scrollOffset)) {
            animateElement(elRef);
        }
    };

    // Throttle the handler firing for performance
    typeof window !== "undefined" && window.addEventListener('scroll', () => {
        throttle(handleScrollAnimation, 500);
    });

    // Perform initial check in case it loads in already in view
    throttle(handleScrollAnimation, 500);

    if (elRef) {
        return elRef;
    } else {
        return null;
    }
};

export default FadeIn;
